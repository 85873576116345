import { typeCast } from './utils';
export function getWindow() {
  if (false) {
    return undefined;
  }

  return typeCast(window);
}
export function getDocument() {
  if (typeof document === 'undefined') {
    return undefined;
  }

  return document;
}
export function getLocalStorage() {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }

  return localStorage;
}
export function getSessionStorage() {
  if (typeof sessionStorage === 'undefined') {
    return undefined;
  }

  return sessionStorage;
}
export function getFullStory() {
  if (typeof FS === 'undefined') {
    return undefined;
  }

  return FS;
}