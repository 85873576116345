export var Alert = {
  variants: {
    toast: {
      container: {
        bg: 'white',
        button: {
          color: 'brand.grey.7c'
        }
      },
      description: {
        mt: '2'
      }
    },
    'toast-with-description': {
      container: {
        bg: 'white',
        p: '4',
        button: {
          color: 'brand.grey.7c'
        }
      },
      description: {
        mt: '2'
      }
    },
    notification: {
      container: {
        zIndex: 'toast',
        maxW: '380px',
        p: 4,
        pr: 10,
        fontWeight: 'semibold',
        textStyle: 'md',
        bgColor: 'white',
        color: 'grey.900'
      },
      description: {
        fontWeight: 'normal',
        textStyle: 'sm',
        mt: '2'
      },
      icon: {
        marginTop: 0.5,
        height: 5,
        width: 5
      }
    },
    message: {
      container: {
        zIndex: 'toast',
        maxW: '190px',
        p: 4,
        textStyle: 'md',
        fontWeight: 'normal',
        bgColor: 'white',
        color: 'grey.900'
      },
      icon: {
        marginTop: 0.5,
        height: 4,
        width: 4
      }
    }
  }
};