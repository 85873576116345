var baseStyle = function baseStyle() {
  return {
    closeButton: {
      color: 'grey.400'
    }
  };
};

export var Modal = {
  baseStyle: baseStyle,
  variants: {
    dls: {
      dialog: {
        p: '10',
        borderRadius: '3xl'
      },
      overlay: {
        bg: ['white', 'rgba(0, 0, 0, 0.25)']
      },
      body: {
        px: '0',
        py: '5'
      },
      header: {
        px: '0',
        pb: '2'
      },
      footer: {
        px: '0'
      }
    }
  },
  sizes: {
    // TODO: Update with unit references from sizes
    sm: {
      dialog: {
        // maxW: 'container.sm',
        maxW: '640px'
      }
    },
    md: {
      dialog: {
        // maxW: 'container.md',
        maxW: '768px'
      }
    },
    lg: {
      dialog: {
        // maxW: 'container.lg',
        maxW: '1024px'
      }
    },
    xl: {
      dialog: {
        maxW: 'container.xl'
      }
    }
  },
  defaultProps: {
    size: 'md'
  }
};