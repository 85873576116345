import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _container, _container2, _container3;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { calc, cssVar } from '@chakra-ui/theme-tools';

var _createMultiStyleConf = createMultiStyleConfigHelpers(switchAnatomy.keys),
    definePartsStyle = _createMultiStyleConf.definePartsStyle,
    defineMultiStyleConfig = _createMultiStyleConf.defineMultiStyleConfig;

var $width = cssVar('switch-track-width');
var $height = cssVar('switch-track-height');
var $diff = cssVar('switch-track-diff');
var diffValue = calc.subtract($width, $height);
var $translateX = cssVar('switch-thumb-x');
var $fontSize = cssVar('switch-track-font-size');
var $fontWeight = cssVar('switch-track-font-weight');
var $lineHeight = cssVar('switch-track-line-height');
var $letterSpacing = cssVar('switch-track-letter-spacing');
var $left = cssVar('switch-track-left');
var baseStyle = definePartsStyle({
  container: {},
  thumb: {},
  track: {
    bg: 'brand.grey.88',
    _checked: {
      bg: 'green'
    }
  }
});
var sizes = {
  sm: definePartsStyle({
    container: (_container = {}, _defineProperty(_container, $width.variable, '3.125rem'), _defineProperty(_container, $height.variable, '1.25rem'), _defineProperty(_container, $fontSize.variable, '12px'), _defineProperty(_container, $lineHeight.variable, '16px'), _defineProperty(_container, $fontWeight.variable, '700'), _defineProperty(_container, $letterSpacing.variable, '-0.75em'), _defineProperty(_container, $left.variable, '7px'), _container)
  }),
  md: {
    container: (_container2 = {}, _defineProperty(_container2, $width.variable, '4.25rem'), _defineProperty(_container2, $height.variable, '1.75rem'), _defineProperty(_container2, $fontSize.variable, '16px'), _defineProperty(_container2, $lineHeight.variable, '25px'), _defineProperty(_container2, $fontWeight.variable, '600'), _defineProperty(_container2, $letterSpacing.variable, 'less-tight'), _defineProperty(_container2, $left.variable, '11px'), _container2)
  }
};
var baseStyles = {
  container: (_container3 = {}, _defineProperty(_container3, $diff.variable, diffValue), _defineProperty(_container3, $translateX.variable, $diff.reference), _defineProperty(_container3, "_rtl", _defineProperty({}, $translateX.variable, calc($diff).negate().toString())), _container3),
  track: {
    width: [$width.reference],
    height: [$height.reference],
    _before: {
      fontSize: [$fontSize.reference],
      fontWeight: [$fontWeight.reference],
      lineHeight: [$lineHeight.reference],
      right: [$left.reference],
      _checked: {
        left: [$left.reference],
        right: 'auto'
      }
    }
  },
  thumb: {
    width: [$height.reference],
    height: [$height.reference],
    _checked: {
      transform: "translateX(".concat($translateX.reference, ")")
    }
  }
};
export var Switch = defineMultiStyleConfig({
  baseStyle: baseStyle,
  sizes: sizes,
  variants: {
    republic: _objectSpread(_objectSpread({}, baseStyles), {}, {
      track: _objectSpread(_objectSpread({}, baseStyles.track), {}, {
        position: 'relative',
        bgColor: 'brand.grey.88',
        _hover: {
          bgColor: 'brand.grey.b3'
        },
        _active: {
          bgColor: 'brand.grey.80'
        },
        _checked: {
          bgColor: 'brand.green.300',
          _hover: {
            bgColor: 'brand.green.200'
          },
          _active: {
            bgColor: 'brand.green.600'
          },
          _disabled: {
            bgColor: 'brand.grey.b3'
          },
          _before: _objectSpread(_objectSpread({}, baseStyles.track._before._checked), {}, {
            content: '"Yes"'
          })
        },
        _disabled: {
          bgColor: 'brand.grey.c7'
        },
        _before: _objectSpread(_objectSpread({}, baseStyles.track._before), {}, {
          position: 'absolute',
          content: '"No"',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'white'
        })
      })
    })
  }
});