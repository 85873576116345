import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/* eslint-disable @nx/enforce-module-boundaries */
import { defineStyleConfig, theme } from '@chakra-ui/react';
export var Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    border: '0.1',
    borderRadius: 'lg',
    _focusVisible: {
      outline: 0,
      boxShadow: '0 0 0 1px white, 0 0 0 3px #6691FF'
    }
  },
  variants: {
    "default": {
      bg: 'white',
      boxShadow: 'inset 0 0 0 1px #c7c7c7',
      color: 'black',
      _hover: {
        borderColor: 'grey.200',
        boxShadow: 'inset 0 0 0 1px #0049FF',
        color: 'blue.500',
        _disabled: {
          boxShadow: 'inset 0 0 0 1px #e0e0e0',
          color: 'rgba(26, 26, 26, 0.3)'
        }
      },
      _disabled: {
        opacity: '1',
        boxShadow: 'inset 0 0 0 1px #e0e0e0',
        color: 'rgba(26, 26, 26, 0.3)'
      }
    },
    primary: {
      bg: 'blue.500',
      color: 'white',
      _hover: {
        bg: 'blue.400',
        _disabled: {
          bg: 'rgba(0, 73, 255, 0.5)',
          opacity: '1'
        }
      },
      _disabled: {
        bg: 'rgba(0, 73, 255, 0.5)',
        opacity: '1'
      }
    },
    secondary: function secondary(props) {
      var _theme$components$But;

      return _objectSpread(_objectSpread({}, (_theme$components$But = theme.components.Button.variants) === null || _theme$components$But === void 0 ? void 0 : _theme$components$But.outline(props)), {}, {
        bg: 'white',
        color: 'grey.900',
        borderColor: 'grey.200',
        _hover: {
          color: 'blue.500',
          borderColor: 'blue.500',
          _disabled: {
            bg: 'white',
            color: 'grey.300',
            borderColor: 'grey.200'
          }
        },
        _active: {
          bg: 'grey.50',
          color: 'blue.800',
          borderColor: 'blue.800'
        },
        _disabled: {
          bg: 'white',
          color: 'grey.300'
        }
      });
    },
    tetriary: function tetriary(props) {
      var _theme$components$But2;

      return _objectSpread(_objectSpread({}, (_theme$components$But2 = theme.components.Button.variants) === null || _theme$components$But2 === void 0 ? void 0 : _theme$components$But2.outline(props)), {}, {
        px: '5',
        py: '2',
        border: '0',
        minW: 'auto',
        color: 'blue.500',
        textDecoration: 'underline',
        textUnderlineOffset: 3,
        _hover: {
          color: 'blue.300',
          _disabled: {
            color: 'grey.300'
          }
        },
        _active: {
          color: 'blue.800'
        },
        _disabled: {
          color: 'grey.300'
        }
      });
    },
    toggle: {
      bg: 'grey.100',
      fontSize: 'xs',
      fontWeight: 'semibold'
    },
    danger: {
      bg: 'red.800',
      color: 'white',
      _hover: {
        _disabled: {
          bg: 'red.800',
          opacity: 0.4
        }
      },
      _disabled: {
        bg: 'red.800',
        opacity: 0.4
      }
    },
    viewContents: {
      p: 0,
      px: 0,
      height: 'unset',
      minW: 'unset'
    }
  },
  sizes: {
    'republic-default': {
      py: '3',
      px: '6'
    },
    large: {
      fontSize: '22px',
      py: '5',
      px: '8',
      borderRadius: 'xl'
    }
  },
  defaultProps: {
    variant: 'default'
  }
});