import Rollbar from 'rollbar';
import { FakeReporter } from './fake-reporter';
export var createErrorReporting = function createErrorReporting(options) {
  if ( // eslint-disable-next-line no-process-env
  false || // eslint-disable-next-line no-process-env
  process.env.STORYBOOK || !(options !== null && options !== void 0 && options.accessToken)) {
    return FakeReporter.init({
      accessToken: 'fakeAccessToken'
    });
  }

  return new Rollbar(options);
};